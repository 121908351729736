"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const snabbdom_1 = require("snabbdom");
function default_1(data, block, options, _children) {
    return snabbdom_1.jsx("iframe", { style: Object.assign({}, options.style), attrs: { width: "100%", height: "100%",
            frameborder: "0",
            allowfullscreen: true,
            src: `https://www.youtube.com/embed/${data}` } });
}
exports.default = default_1;
