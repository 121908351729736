"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const snabbdom_1 = require("snabbdom");
function default_1(data, block, options, children) {
    let parent = block.parent;
    let value = {};
    if (parent.db['v'])
        value = parent.db['v'];
    let disabled = false;
    if (parent.db['c'])
        disabled = true;
    const click = function (ev) {
        if (!disabled) {
            let newValue = Object.assign({}, value);
            newValue[block.id] = (newValue[block.id] !== true);
            parent.update(Object.assign(Object.assign({}, parent.db), { v: newValue }));
        }
    };
    let button = snabbdom_1.jsx("button", { on: { click }, style: Object.assign({}, options.style), class: { 'btn-outline-secondary': true, 'btn': true }, attrs: { 'disabled': disabled, type: 'button' } }, children);
    if (value[block.id]) {
        button = snabbdom_1.jsx("button", { on: { click }, style: Object.assign({}, options.style), class: { 'btn-secondary': true, 'btn': true }, attrs: { 'disabled': disabled, type: 'button' } }, children);
    }
    return button;
}
exports.default = default_1;
