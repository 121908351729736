"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.view = exports.init = exports.update = void 0;
const snabbdom_1 = require("snabbdom");
function update(_message, state, _dispatch) {
    return state;
}
exports.update = update;
function init(state, _dispatch) {
    return state;
}
exports.init = init;
function view({ state, dispatch }) {
    return snabbdom_1.jsx("div", { class: { container: true } },
        snabbdom_1.jsx("h1", null, "404"),
        snabbdom_1.jsx("p", null, "I could not find your page."),
        snabbdom_1.jsx("p", null, "I am so sorry."));
}
exports.view = view;
let FourOhFour = { view, init, update };
exports.default = FourOhFour;
