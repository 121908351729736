"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const snabbdom_1 = require("snabbdom");
const fontawesome_svg_core_1 = require("@fortawesome/fontawesome-svg-core");
const free_solid_svg_icons_1 = require("@fortawesome/free-solid-svg-icons");
fontawesome_svg_core_1.library.add(free_solid_svg_icons_1.fas);
function Icon({ fa }) {
    const lookup = {
        prefix: 'fas',
        iconName: fa
    };
    const definition = fontawesome_svg_core_1.findIconDefinition(lookup);
    const i = fontawesome_svg_core_1.icon(definition);
    return snabbdom_1.jsx("i", { props: { innerHTML: i.html[0] } });
}
exports.default = Icon;
