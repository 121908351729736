"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DoenetScore = exports.view = exports.init = exports.update = exports.putScore = void 0;
const snabbdom_1 = require("snabbdom");
const message_1 = require("../message");
const cloud_1 = require("@doenet/cloud");
const state_1 = require("../state");
function putScore(state, dispatch, score) {
    return __awaiter(this, void 0, void 0, function* () {
        if (!state.doenetToken) {
            return;
        }
        const url = new URL(window.location.toString());
        url.pathname = state_1.stateToPathname(state);
        const _result = yield cloud_1.putScore(score, url.toString());
        if (_result.ok) {
            dispatch(new message_1.SetDoenetScoreMessage(score, url.pathname));
        }
        return;
    });
}
exports.putScore = putScore;
function requestScore(state, dispatch) {
    return __awaiter(this, void 0, void 0, function* () {
        const url = new URL(window.location.toString());
        window.putTheScore = function (s) {
            return __awaiter(this, void 0, void 0, function* () {
                putScore(state, dispatch, s);
            });
        };
        url.pathname = state_1.stateToPathname(state);
        const score = yield cloud_1.getScore(url.toString());
        if (score === null)
            dispatch(new message_1.SetDoenetScoreMessage(0, url.pathname));
        else
            dispatch(new message_1.SetDoenetScoreMessage(score, url.pathname));
        return;
    });
}
function update(message, state, dispatch) {
    if (message.type === 'set-doenet-token') {
        if (state.repository && state.texFilename) {
            requestScore(state, dispatch);
        }
    }
    if (message.type === 'navigate-to') {
        if (state.repository && state.texFilename && state.doenetToken) {
            requestScore(state, dispatch);
        }
    }
    if (message.type === 'set-doenet-score') {
        if (message.pathname === state_1.stateToPathname(state)) {
            let result = Object.assign({}, state);
            if (result.scores === undefined)
                result.scores = new Map();
            result.scores.set(message.pathname, message.score);
            return result;
        }
    }
    return state;
}
exports.update = update;
function init(state, dispatch) {
    return state;
}
exports.init = init;
function view({ state, dispatch }) {
    if (state.repository && state.texFilename && state.scores && state.doenetToken) {
        if (state.scores) {
            let pathname = state_1.stateToPathname(state);
            if (state.scores.has(pathname)) {
                let score = state.scores.get(pathname);
                if (score !== undefined) {
                    return snabbdom_1.jsx("div", { style: { width: "1in" }, class: { progress: true, "mr-1": true, "ms-2": true, "mt-auto": true, "mb-auto": true }, attrs: { "data-toggle": "tooltip", "data-placement": "bottom", title: `${Math.floor(score * 100.0)}%` } },
                        snabbdom_1.jsx("div", { style: { "width": `${score * 100.0}%` }, class: { "progress-bar": true, "bg-success": true }, attrs: { role: "progressbar", "aria-valuenow": `${Math.floor(score * 100.0)}`, "aria-valuemin": "0", "aria-valuemax": "100" } }));
                }
            }
        }
    }
    return snabbdom_1.jsx("div", null);
}
exports.view = view;
exports.DoenetScore = { view, init, update };
exports.default = exports.DoenetScore;
