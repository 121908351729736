"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.requestRepository = exports.updateRepository = exports.GithubRepository = void 0;
const message_1 = require("./message");
class GithubRepository {
    constructor(details) {
        this.details = details;
        this.name = details.name;
        this.branch = details.branch;
        this.owner = details.owner;
        this.sha = this.branch.commit.sha;
    }
    htmlUrl() {
        return this.details.html_url;
    }
    blobLink(filename) {
        return `https://github.com/${this.owner.login}/${this.name}/blob/${this.branch.name}/${filename}`;
    }
    editLink(filename) {
        return `https://github.com/${this.owner.login}/${this.name}/edit/${this.branch.name}/${filename}`;
    }
    issueLink(filename, title) {
        const directLink = this.blobLink(filename);
        return `https://github.com/${this.owner.login}/${this.name}/issues/new?title=${title}&body=%0A%0ASee%20[${filename}](${directLink})`;
    }
    url(filename) {
        const url = `/github/${this.owner.login}/${this.name}/${this.sha}/${filename}`;
        return url;
    }
}
exports.GithubRepository = GithubRepository;
function updateRepository(message, state, _dispatch) {
    if (message.type === 'set-repository') {
        return Object.assign(Object.assign({}, state), { repository: message.repository });
    }
    return state;
}
exports.updateRepository = updateRepository;
function requestRepository(owner, repo, dispatch) {
    return __awaiter(this, void 0, void 0, function* () {
        let url = `/github/${owner}/${repo}.json`;
        try {
            let response = yield fetch(url);
            if (!(response.ok)) {
                dispatch(new message_1.ErrorMessage(response.statusText));
                return;
            }
            let json = yield response.json();
            let result = new GithubRepository(json);
            dispatch(new message_1.SetRepositoryMessage(result));
            return result;
        }
        catch (error) {
            dispatch(new message_1.ErrorMessage(error.toString()));
        }
        return undefined;
    });
}
exports.requestRepository = requestRepository;
