"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const snabbdom_1 = require("snabbdom");
const message_1 = require("./message");
function onClick(dispatch, href) {
    return function (ev) {
        ev.preventDefault();
        dispatch(new message_1.NavigationMessage(href));
        history.pushState(null, '', href);
        ev.stopPropagation();
    };
}
function Link(props, children) {
    return snabbdom_1.jsx("a", Object.assign({}, props, { attrs: { href: '#' }, on: { click: onClick(props.dispatch, props.href) } }), children);
}
exports.default = Link;
