"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.view = void 0;
require("./spinner.css");
const snabbdom_1 = require("snabbdom");
const tea_1 = require("./tea");
function view({ state, dispatch }) {
    return snabbdom_1.jsx("div", { class: { spinner: true } }, "Loading...");
}
exports.view = view;
const Spinner = { view, init: tea_1.emptyInit, update: tea_1.emptyUpdate };
exports.default = Spinner;
