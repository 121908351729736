"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const snabbdom_1 = require("snabbdom");
function default_1(data, block, options, children) {
    let value = '';
    if (block.db['v'])
        value = block.db['v'];
    const change = function (ev) {
        block.update(Object.assign(Object.assign({}, block.db), { v: ev.target.value }));
    };
    return snabbdom_1.jsx("textarea", { on: { change }, style: Object.assign(Object.assign({}, options.style), { resize: 'none' }), class: { 'form-control': true }, attrs: { rows: '3' } }, value);
}
exports.default = default_1;
