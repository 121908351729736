"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ErrorMessage = exports.WrappedMessage = exports.DoenetSavingMessage = exports.SetDoenetShadowMessage = exports.PatchDoenetDatabaseMessage = exports.SetDoenetDatabaseMessage = exports.SetDoenetScoreMessage = exports.SetDoenetTokenMessage = exports.DoenetDisconnectMessage = exports.SetRepositoryMessage = exports.TerminalLogMessage = exports.ViewSourceMessage = exports.UpdateTimeMessage = exports.ResizeMessage = exports.SetDviMessage = exports.NavigationMessage = void 0;
class NavigationMessage {
    constructor(path) {
        this.path = path;
        this.type = "navigate-to";
    }
    ;
}
exports.NavigationMessage = NavigationMessage;
class SetDviMessage {
    constructor(dvi, hsize) {
        this.dvi = dvi;
        this.hsize = hsize;
        this.type = "set-dvi";
    }
    ;
}
exports.SetDviMessage = SetDviMessage;
class ResizeMessage {
    constructor(width, height) {
        this.width = width;
        this.height = height;
        this.type = "window-resize";
    }
    ;
}
exports.ResizeMessage = ResizeMessage;
class UpdateTimeMessage {
    constructor() {
        this.type = "update-time";
    }
    ;
}
exports.UpdateTimeMessage = UpdateTimeMessage;
class ViewSourceMessage {
    constructor(url, source) {
        this.url = url;
        this.source = source;
        this.type = "view-source";
    }
    ;
}
exports.ViewSourceMessage = ViewSourceMessage;
class TerminalLogMessage {
    constructor(text) {
        this.text = text;
        this.type = "terminal-log";
    }
    ;
}
exports.TerminalLogMessage = TerminalLogMessage;
class SetRepositoryMessage {
    constructor(repository) {
        this.repository = repository;
        this.type = "set-repository";
    }
    ;
}
exports.SetRepositoryMessage = SetRepositoryMessage;
class DoenetDisconnectMessage {
    constructor() {
        this.type = "doenet-disconnect";
    }
    ;
}
exports.DoenetDisconnectMessage = DoenetDisconnectMessage;
class SetDoenetTokenMessage {
    constructor(token) {
        this.token = token;
        this.type = "set-doenet-token";
    }
    ;
}
exports.SetDoenetTokenMessage = SetDoenetTokenMessage;
class SetDoenetScoreMessage {
    constructor(score, pathname) {
        this.score = score;
        this.pathname = pathname;
        this.type = "set-doenet-score";
    }
    ;
}
exports.SetDoenetScoreMessage = SetDoenetScoreMessage;
class SetDoenetDatabaseMessage {
    constructor(db, pathname) {
        this.db = db;
        this.pathname = pathname;
        this.type = "set-doenet-database";
    }
    ;
}
exports.SetDoenetDatabaseMessage = SetDoenetDatabaseMessage;
class PatchDoenetDatabaseMessage {
    constructor(pathname, key, value) {
        this.pathname = pathname;
        this.key = key;
        this.value = value;
        this.type = "patch-doenet-database";
    }
    ;
}
exports.PatchDoenetDatabaseMessage = PatchDoenetDatabaseMessage;
class SetDoenetShadowMessage {
    constructor(db, pathname) {
        this.db = db;
        this.pathname = pathname;
        this.type = "set-doenet-shadow";
    }
    ;
}
exports.SetDoenetShadowMessage = SetDoenetShadowMessage;
class DoenetSavingMessage {
    constructor(pathname) {
        this.pathname = pathname;
        this.type = "doenet-saving";
    }
    ;
}
exports.DoenetSavingMessage = DoenetSavingMessage;
class WrappedMessage {
    constructor(message, nonce) {
        this.message = message;
        this.nonce = nonce;
        this.type = "wrapped-message";
    }
    ;
}
exports.WrappedMessage = WrappedMessage;
class ErrorMessage {
    constructor(error) {
        this.error = error;
        this.type = "error";
    }
    ;
}
exports.ErrorMessage = ErrorMessage;
