"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.emptyUpdate = exports.emptyInit = void 0;
function emptyInit(state, _dispatch) {
    return state;
}
exports.emptyInit = emptyInit;
function emptyUpdate(_message, state, _dispatch) {
    return state;
}
exports.emptyUpdate = emptyUpdate;
