"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.stateToPathname = void 0;
function stateToPathname(state) {
    if (state.repository && state.repository.owner && state.repository.owner.login &&
        state.repository.name && state.texFilename)
        return `/${state.repository.owner.login}/${state.repository.name}/${state.texFilename}`;
    return '';
}
exports.stateToPathname = stateToPathname;
