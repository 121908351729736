"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.withDefaultPrevented = void 0;
function withDefaultPrevented(f) {
    return function (ev) {
        ev.preventDefault();
        f(ev);
        ev.stopPropagation();
    };
}
exports.withDefaultPrevented = withDefaultPrevented;
